import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/scss/styles.scss";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Error404 from "./components/Error404";
import Intro from "./components/Intro/Intro";
import Usados from "./components/Usados/Usados";
import Servicios from "./components/Servicios/Servicios";
import Botonera from "./components/Botonera/Botonera";
import Slider from "./components/Slider";
import WspFloat from "./components/WspFloat/WspFloat";
import Condiciones from "./components/Condiciones/Condiciones";
import CuentasBancarias from "./components/CuentasBancarias/CuentasBancarias";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Header />

          <Intro />
          <Usados />
          <Servicios />
          {/* <Slider /> */}
          <Footer />
          <WspFloat info={``} />
        </Route>
        <Route exact path="/basesycondiciones">
          <Header />
          <Condiciones />
          <Footer />
          <WspFloat info={``} />
        </Route>
        <Route exact path="/cuentas-bancarias">
          <Header />
          <CuentasBancarias />
          <Footer />
          <WspFloat info={``} />
        </Route>
        <Route path="*">
          <Header bg="bgDark" />
          <Error404 />
          <Footer />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
