import { Col, Container, Row, Card, Accordion, Button } from "react-bootstrap";
import fiatlogo from "../../assets/img/cuentas/fiat.png";
import jeeplogo from "../../assets/img/cuentas/jeep.png";
import peugeotlogo from "../../assets/img/cuentas/peugeot.png";
import { useState } from "react";
import { FaCopy } from "react-icons/fa";

const CuentasBancarias = () => {
  const brands = [
    { name: "Fiat", imgSrc: fiatlogo },
    { name: "Jeep", imgSrc: jeeplogo },
    { name: "Peugeot", imgSrc: peugeotlogo },
  ];

  const banks = [
    {
      brand: "Fiat",
      accounts: [
        {
          name: "ICBC",
          holder: "PINEROLO",
          cuit: "30-70913671-9",
          accountNumber: "CC en pesos nº 9351/02105804/74",
          cbu: "0150931502000105804747",
        },
        {
          name: "Santander Río",
          holder: "PINEROLO",
          cuit: "30-70913671-9",
          accountNumber: "CC en pesos nº 199-011282/0",
          cbu: "0720199420000001128204",
        },
        {
          name: "Banco Nación",
          holder: "PINEROLO",
          cuit: "30-70913671-9",
          accountNumber: "CC en pesos nº 2622-061100459/22",
          cbu: "0110611020061100459228",
        },
        {
          name: "BBVA Francés",
          holder: "PINEROLO",
          cuit: "30-70913671-9",
          accountNumber: "CC en pesos nº 473-1436-0",
          cbu: "0170473420000000143608",
        },
        {
          name: "Galicia",
          holder: "PINEROLO",
          cuit: "30-70913671-9",
          accountNumber: "CC en pesos nº 4670-5-199-8",
          cbu: "0070199620000004670580",
        },
        {
          name: "Bancor",
          holder: "PINEROLO",
          cuit: "30-70913671-9",
          accountNumber: "CC en pesos nº 331/004026200",
          cbu: "0200331101000040026201",
        },
      ],
    },
    {
      brand: "Peugeot",
      accounts: [
        {
          name: "Macro",
          holder: "MONTER SA",
          cuit: "30-71769661-8",
          accountNumber: "Nro. 3393094204730611",
          alias: "CONO.ANOCHE.COSA",
          cbu: "28503093430094204730611",
        },
        {
          name: "BBVA",
          holder: "MONTER SA",
          cuit: "30-71769661-8",
          accountNumber: "CC en pesos nº 290-022873/3",
          cbu: "0170209520000002287333",
        },
        {
          name: "Santander Río",
          holder: "MONTER SA",
          cuit: "30-71769661-8",
          accountNumber: "CC en pesos nº 199-012139/2",
          cbu: "0720199420000001213922",
        },
        {
          name: "ICBC",
          holder: "MONTER SA",
          cuit: "30-71769661-8",
          accountNumber: "CC en pesos nº 0804/02107720/28",
          alias: "ALMEJA.CONDOR.PIE",
          cbu: "0150804602000107720281",
        },
        {
          name: "Bancor",
          holder: "MONTER SA",
          cuit: "30-71769661-8",
          accountNumber: "CC $ 307 0002876201",
          alias: "ORDEN.COCHE.DIODO",
          cbu: "0200307601000002876215",
        },
      ],
    },
    {
      brand: "Jeep",
      accounts: [
        {
          name: "Santander Río",
          holder: "LANDCAR S.A.",
          cuit: "30-71705502-7",
          accountNumber: "CC en pesos nº 199-011880/4",
          alias: "ASPA.BALDE.ATUN",
          cbu: "0720199420000001188040",
        },
        {
          name: "Galicia",
          holder: "LANDCAR S.A.",
          cuit: "30-71705502-7",
          accountNumber: "CC nº 0060003-7-199-7",
          cbu: "0070199620000006003779",
        },
        {
          name: "BBVA",
          holder: "LANDCAR S.A.",
          cuit: "30-71705502-7",
          accountNumber: "CC $ nº 290-020100/6",
          cbu: "0170290520000002010065",
        },
        {
          name: "ICBC",
          holder: "LANDCAR S.A.",
          cuit: "30-71705502-7",
          accountNumber: "CC $ 0515/0211308",
          cbu: "0150515302000113081127",
        },
      ],
    },
  ];

  const [selectedBrand, setSelectedBrand] = useState(null);

  const getBankAccounts = (brand) => {
    const selectedBank = banks.find((b) => b.brand === brand);
    return selectedBank ? selectedBank.accounts : [];
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("CBU copiado al portapapeles!");
    });
  };

  return (
    <Container className="pb-5 cuentasBancarias">
      <Row>
        <Col className="pt-5 pb-">
          <h1 className="text-center fw-bold pt-4 pb-2">INFORMACIÓN BANCARIA Y MÉTODOS DE PAGO</h1>
          <h2 className="text-center" style={{ fontSize: "18px" }}>
            Te brindamos toda la información para que puedas realizar tus pagos de forma fácil, segura y sin pérdidas de
            tiempo.
          </h2>
          <hr className="mt-5 mb-5" />
          {!selectedBrand && <h4 className="text-center pb-5 ">Seleccioná la marca de tu vehículo</h4>}
        </Col>
      </Row>
      <Row className="justify-content-center">
        {!selectedBrand &&
          brands.map((brand, index) => (
            <Col key={index} md={3} xs={11} className="mb-4 mx-auto">
              <Card
                className="text-center shadow hover-shadow"
                style={{
                  borderRadius: "15px",
                  cursor: "pointer",
                  transition: "transform 0.2s ease, box-shadow 0.2s ease",
                }}
                onClick={() => setSelectedBrand(brand.name)}
              >
                <Card.Img
                  variant="top"
                  src={brand.imgSrc}
                  alt={`${brand.name} Logo`}
                  style={{ maxHeight: "100px", objectFit: "contain", padding: "1rem" }}
                />
                <Card.Body>
                  <Card.Title>{brand.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}

        {selectedBrand && (
          <>
            <Col md={8} className="mb-4 mx-auto">
              <h1 className="text-center mb-4">{selectedBrand}</h1>
              <Accordion>
                {getBankAccounts(selectedBrand).map((account, index) => (
                  <Accordion.Item
                    eventKey={index.toString()}
                    key={index}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      marginBottom: "10px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Accordion.Header>
                      <p className="fw-bold text-uppercase mb-0">{account.name}</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-1">
                        <strong>{account.holder}</strong>
                      </p>
                      <p className="mb-1">
                        <strong>CUIT:</strong> {account.cuit}
                      </p>
                      <p className="mb-3">
                        <strong>Cuenta:</strong> {account.accountNumber}
                      </p>
                      <p
                        className="mb-0 d-flex align-items-center mb-3"
                        onClick={() => copyToClipboard(account.cbu)}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            padding: "5px 10px",
                            backgroundColor: "#444",
                            color: "#fff",
                            borderRadius: "5px",
                            flex: "1",
                            padding: "12px",
                          }}
                        >
                          <strong>CBU:</strong> {account.cbu} <FaCopy className="ms-2" />
                        </span>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
              <div className="d-flex justify-content-end mt-4">
                <Button variant="light" onClick={() => setSelectedBrand(null)}>
                  &lt; Volver
                </Button>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default CuentasBancarias;
